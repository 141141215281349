import {faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons'
import {faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {Fade} from 'react-reveal'
import Layout from '../components/Layout'
import {Seo} from '../components/Seo'

const Contact = () => {
  return (
    <Layout>
      <Seo title='Kontakt | Modelowanie Sylwetki' />

      <div className='page-container'>
        <div className='page contact'>
          <div className='content-1'>
            <div className='section'>
              <div className='text'>
                <h1>Kontakt</h1>
                <div className='section'>
                  <div className='social'>
                    <h2>Zadzwoń w celu Konsultacji</h2>
                    <a href='tel:+48 728 008 928'>
                      <FontAwesomeIcon icon={faPhone} />
                      <p>+48 728 008 928</p>
                    </a>
                    <a href='mailto:studio@modelowaniesylwetki-wawer.pl'>
                      <FontAwesomeIcon icon={faEnvelope} />
                      <p>studio@modelowaniesylwetki-wawer.pl</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className='text'>
                <h2>Napisz do nas</h2>
                <div className='social-media'>
                  <a
                    href='https://www.facebook.com/Modelowanie-Sylwetki-Wawer-101096555690380/?ref=page_internal'
                    target='_blank'
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a
                    href='https://www.instagram.com/modelowanie_sylwetki_wawer/'
                    target='_blank'
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
              </div>
              <div className='text'>
                <h2>Godziny Otwarcia</h2>
                <p>
                  Poniedziałek - Piątek 9:00-21:00 <br />
                  Sobota 9:00 - 15:00
                </p>
              </div>
              <div className='text'>
                <h2>Adres</h2>
                <p>
                  ul. Trakt Lubelski 300B, <br />
                  04-667 Warszawa
                </p>
              </div>
            </div>
          </div>
          <div className='map-container'>
            <iframe
              scrolling='no'
              marginheight='0'
              marginwidth='0'
              src='https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=ul.%20Trakt%20Lubelski%20300B,%2004-667%20Warszawa+(Modelowanie%20Sylwetki)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
              width='100%'
              height='100%'
              frameborder='0'
            >
              <a href='https://www.gps.ie/marine-gps/'>ship tracker</a>
            </iframe>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
